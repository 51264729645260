import * as React from "react";
import * as ReactDOM from "react-dom/client";
import * as ReactRouter from "react-router-dom";

import Router from "./router";

import "./resources/css/global";


const root = ReactDOM.createRoot(document.getElementById("root") as ReactDOM.Container);
root.render(<Router />);