import * as React from "react";
import * as ReactDOM from "react-dom";

import NavbarPartial from "../partials/navbar";
import Card from "../components/card";


export default function AboutRoute(): React.ReactElement {
    document.title = "About";

    return (
        <section className="body" id="about">
            <NavbarPartial />

            
            
            <Card title="This is a test card">
                Example Content
            </Card>
        </section>
    );
}