import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Error404Route from "./routes/404";
import HomeRoute from "./routes/home";
import AboutRoute from "./routes/about";


export default function Router(): React.ReactElement {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Error404Route />}></Route>
                <Route path="/" element={<HomeRoute />}></Route>
                <Route path="/about" element={<AboutRoute />}></Route>
            </Routes>
        </BrowserRouter>
    );
};