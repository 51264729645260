import * as React from "react";

import "../resources/css/components/navbarItem";


export default function NavbarItem(props: {name: string, href?: string, children?}): React.ReactElement {
    const active = props.href == window.location.href.substring(window.location.origin.length);

    return (
        <div className="navbar-item-container">
            <div style={{position: "relative"}}>
                <a href={props.href} style={props.href ? {} : {cursor: "default"}}>
                    {props.name}
                </a>
                {active &&
                    <div className="active-indicator"></div>
                }
            </div>
            {props.children &&
                <div className="navbar-item-dropdown-container glass shadow">
                    {props.children}
                </div>
            }
        </div>
    );
}