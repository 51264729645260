import * as React from "react";
import * as ReactDOM from "react-dom";

import NavbarPartial from "../partials/navbar";
import Card from "../components/card";


export default function HomeRoute(): React.ReactElement {
    document.title = "Home";

    return (
        <section className="body" id="home">
            <NavbarPartial />

            <Card title="Home Page">
                <div style={{height: 1000}}>
                    Test Home Page Content
                </div>
            </Card>
        </section>
    );
}