import * as React from "react";

import "../resources/css/components/card";


export default function Card(props: {title: string, children?}): React.ReactElement {
    return (
        <div className="card-container shadow">
            <div className="card-header glass">
                {props.title}
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    );
}