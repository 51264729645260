import * as React from "react";


export default function Error404Route(): React.ReactElement {
    return (
        <div>
            <h1>404</h1>
            <p>Page not found.</p>
        </div>
    );
}