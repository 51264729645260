import * as React from "react";

import NavbarItem from "../components/navbarItem";

import "../resources/css/partials/navbar";


export default function NavbarPartial(): React.ReactElement {
    const [classes, setClasses] = React.useState<string>("navbar-container shadow floating");


    function updateFloating(): void {
        if(document.documentElement.scrollTop == 0 && !classes.includes("floating")) 
        {
            setClasses("navbar-container shadow floating");
        } 
        else if(document.documentElement.scrollTop > 0 && classes.includes("floating")) 
        {
            setClasses("navbar-container shadow");
        }
    }

    window.addEventListener("scroll", (e) => {
        updateFloating();
    });

    updateFloating(); //does an initial check if the page is already scrolled on load


    return (
        <header className={classes}>
            <NavbarItem name="Home" href="/"/>
            <NavbarItem name="About" href="/about"/>
            <NavbarItem name="Test">
                <NavbarItem name="Child 1"/>
                <NavbarItem name="Child 2"/>
            </NavbarItem>
        </header>
    );
}